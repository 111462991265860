import React from 'react';
import Layout from './component/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LandingPage } from './component/landingPage/LandingPage';
import VirtualAiPhotoshoot from './component/virtualAiPhotoshoot/VirtualAiphotoshoot';
import AboutUs from './component/aboutUs/AboutUs';
import VirtualTrial2 from './component/virtualTrial2/VirtualTrial2';
import ContactLayout from './component/contact/ContactLayout';
import Terms from './component/Conditions/Terms';
import PrivacyPolicy from './component/Conditions/PrivacyPolicy';
import OurProducts from './component/ourProducts/OurProducts';
import Demo from './component/ourDemo/Demo';
import DemoVP from './component/DemoVP/DemoVP';
import DemoVT from './component/DemoVT/DemoVT';
import Plans from './component/plansVP/Plans';
import Contact from './component/LinkTree/Contact'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<LandingPage/>}/>
          <Route path='/virtualAiPhotoshoot' element={<VirtualAiPhotoshoot/>}/>
          <Route path="/virtualTrial" element={<VirtualTrial2/>}/>
          <Route path='/aboutus' element={<AboutUs/>}/>
          <Route path='/contactus' element={<ContactLayout/>}/>
          <Route path='/TermsAndConditions' element={<Terms/>}/>
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
          <Route path='/ourProducts' element={<OurProducts/>}/>
          <Route path='/Demo' element={<Demo/>}/>
          <Route path='/DemoVP' element={<DemoVP/>}/>
          <Route path='/DemoVT' element={<DemoVT/>}/>
          <Route path='/plansVP' element={<Plans/>}/>
          <Route path='/Contact' element={<Contact/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
